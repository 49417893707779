import React, { useState, useEffect } from 'react';
import { usePgTraining } from '../../hooks/usePgTraining';
import useIntroductoryTestQuestion from '../../hooks/useQuestion';
import { TestQuestionDto } from '../../types/TestQuestionDto';
import IntroductionTestPresenter from './presenter';

const IntroductionTest = () => {
  const [time, setTime] = useState(0);
  const [questions, setQuestions] = useState<TestQuestionDto[]>([]);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [result, setResult] = useState<{ [key: number]: boolean | null }>({});
  const idList = useIntroductoryTestQuestion(); // ランダムなIDリストを取得
  const { fetchQuestions } = usePgTraining(); // 問題を取得するカスタムフック

  useEffect(() => {
    const timer = setInterval(() => setTime((prev) => prev + 1), 1000);

    // 問題をAPIから取得
    if (idList.length > 0) {
      fetchQuestions(idList).then((data) => {
        setQuestions(data);
      });
    }

    return () => clearInterval(timer);
  }, [idList, fetchQuestions]);

  // timeをhh:mm:ss形式にフォーマットする関数
  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // ユーザーの回答を管理する関数
  const handleAnswerChange = (questionId: number, value: string) => {
    setUserAnswers({ ...userAnswers, [questionId]: value });
  };

  // 回答を送信して正誤判定を行う関数
  const handleSubmitAnswer = (questionId: number) => {
    const correctAnswer = questions.find(q => q.id === questionId)?.answer;
    const userAnswer = userAnswers[questionId];
    const isCorrect = correctAnswer === userAnswer;
    setResult({ ...result, [questionId]: isCorrect });
  };

  return (
    <IntroductionTestPresenter
      testQuestions={questions}
      time={formatTime(time)} // フォーマットされた時間を渡す
      userAnswers={userAnswers}
      result={result}
      onAnswerChange={handleAnswerChange}
      onSubmitAnswer={handleSubmitAnswer}
    />
  );
};

export default IntroductionTest;
