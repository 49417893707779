import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>入門テストへようこそ！</h1>
      <p>簡単な説明文をここに追加してください。</p>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/introduction-test')}
      >
        テストを開始する
      </Button>
    </div>
  );
};

export default HomePage;
