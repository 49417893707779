import { useState, useEffect } from 'react';

const useIntroductoryTestQuestion = () => {
  const [idList, setIdList] = useState<number[]>([]);

  /**
   * 入門テスト用問題番号リスト取得
   * @returns 問題番号リスト
   */
  const getIntroductoryTestList = () => {
    // 1〜10の範囲からランダムに3つ選択
    const firstSet = Array.from({ length: 10 }, (_, i) => i + 1)
      .sort(() => 0.5 - Math.random())
      .slice(0, 3);

    // 11〜30の範囲からランダムに2つ選択
    const secondSet = Array.from({ length: 20 }, (_, i) => i + 11)
      .sort(() => 0.5 - Math.random())
      .slice(0, 2);

    // 両方のセットを結合して昇順にソート
    return [...firstSet, ...secondSet].sort((a, b) => a - b);
  };

  // コンポーネントのマウント時にランダムIDを生成
  useEffect(() => {
    setIdList(getIntroductoryTestList());
  }, []);

  return idList;
};

export default useIntroductoryTestQuestion;
