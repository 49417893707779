import axios, { AxiosError, AxiosResponse } from "axios"
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { TestQuestionDto } from "../types/TestQuestionDto";

export const usePgTraining = () => {

    const navigate = useNavigate();

    const PgTrainingApi = useMemo(()=> {
    const instance = axios.create({
        adapter: "fetch",
        baseURL: process.env.REACT_APP_API_BASE_URL,
        timeout: 30000,
      });
    
      instance.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error: AxiosError) => {
            if(!error.response) {
                navigate("/error/500")
                return Promise.reject(error);
            }


        switch (error.response.status) {
            case 404:
              navigate("/error/404");
              break;
            case 500:
              navigate("/error/500");
              break;
          }

          return Promise.reject(error);
        }
      );

      return instance;
    },[navigate]);


    /**
     * 問題文取得
     * param 問題番号リスト
     * return テスト問題Dto
     */
  const fetchQuestions = useCallback(async (idList: number[]):Promise<TestQuestionDto[]> => {

    return PgTrainingApi.post(
        "introductory-tests",{idList}
    )
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        navigate("/error/400");
    })

  }, [PgTrainingApi, navigate]);

  return {
    fetchQuestions,
  };
}

