import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import IntroductionTest from './components/IntroductionTestPage/container';
import './style.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/introduction-test" element={<IntroductionTest />} />
      </Routes>
    </Router>
  );
};

export default App;
