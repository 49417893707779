import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Typography, Box, TextField, Paper, IconButton } from '@mui/material';
import { TestQuestionDto } from '../../types/TestQuestionDto';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/material/styles';
import remarkGfm from 'remark-gfm'; // テーブル表示をサポート

type Props = {
  testQuestions: TestQuestionDto[];
  time: string;
  userAnswers: { [key: number]: string };
  result: { [key: number]: boolean | null };
  onAnswerChange: (questionId: number, value: string) => void;
  onSubmitAnswer: (questionId: number) => void;
};

const IntroductionTestPresenter = ({
  testQuestions,
  time,
  userAnswers,
  result,
  onAnswerChange,
  onSubmitAnswer,
}: Props) => {
  const [copied, setCopied] = useState(false);
  const theme = useTheme();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box sx={{ backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', position: 'relative' }}>
      {/* タイトルと経過時間 */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          backgroundColor: '#fff',
          zIndex: 1000,
          p: 2,
          borderBottom: '1px solid #ddd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          paddingRight: '20px',
        }}
      >
        <Typography variant="h4">プログラミングトレーニング入門テスト</Typography>
        {/* 経過時間 */}
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            px: 3,
            py: 1,
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
          }}
        >
          <Typography variant="h6" sx={{ marginRight: '10px' }}>
            経過時間
          </Typography>
          <Typography variant="h6">{time}</Typography>
        </Box>
      </Box>

      {/* メインコンテンツ */}
      <Box sx={{ width: '66%', maxWidth: '900px', mt: '80px' }}>
        {testQuestions.map((q, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              mb: 4,
              p: 3,
              borderLeft: `5px solid ${theme.palette.primary.main}`,
              backgroundColor: '#fafafa',
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                '&:before': {
                  content: '""',
                  display: 'block',
                  width: '6px',
                  height: '100%',
                  backgroundColor: theme.palette.primary.main,
                  marginRight: '10px',
                },
              }}
            >
              問題 {q.id}: {q.title}
            </Typography>

            {/* 問題文をMarkdownで表示 */}
            <Box
              mt={2}
              mb={2}
              p={2}
              sx={{
                borderRadius: '8px',
                position: 'relative',
                border: '1px solid #ddd',
              }}
            >
              <ReactMarkdown
                components={{
                  code({ className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return match ? (
                      <Box position="relative">
                        <SyntaxHighlighter style={prism} language={match[1]}>
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                        <CopyToClipboard text={String(children)} onCopy={handleCopy}>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: 10,
                              right: 10,
                              backgroundColor: copied ? theme.palette.primary.main : '#f5f5f5',
                              ':hover': {
                                backgroundColor: '#e0e0e0',
                              },
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </CopyToClipboard>
                      </Box>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
                remarkPlugins={[remarkGfm]} // テーブル表示に対応
              >
                {q.problem_statement || ''}
              </ReactMarkdown>
            </Box>

            {/* 回答入力フィールド */}
            <TextField
              label="回答を入力してください"
              variant="outlined"
              fullWidth
              value={userAnswers[q.id] || ''}
              onChange={(e) => onAnswerChange(q.id, e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmitAnswer(q.id)}
              sx={{ mb: 2 }}
            >
              回答
            </Button>

            {/* 正誤判定結果を表示 */}
            {result[q.id] !== undefined && (
              <Typography
                sx={{
                  color: result[q.id] ? theme.palette.success.main : theme.palette.error.main,
                }}
              >
                {result[q.id] ? '正解です' : '不正解です'}
              </Typography>
            )}
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default IntroductionTestPresenter;
